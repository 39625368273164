import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyCFZGCMbNl-oBxWXU1sscTIWaD6YAzckCM",
    authDomain: "somuto-e4c40.firebaseapp.com",
    projectId: "somuto-e4c40",
    storageBucket: "somuto-e4c40.appspot.com",
    messagingSenderId: "1048725295148",
    appId: "1:1048725295148:web:5a9cdef2895a9ce3c3c189"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config)
} else {
    firebase.app();
}

export const ref = firebase.database().ref()
export const firebaseAuth = firebase.auth