import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route
} from "react-router-dom";
import "./App.scss";
import { firebaseAuth } from './config/constants';
import CenteredContentWrapper from './modules/CenteredContentWrapper';
import DashboardPage from "./pages/DashboardPage";
import LandingPage from "./pages/LandingPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import PlaylistPage from "./pages/PlaylistPage";
//import PrivateRoute from "./PrivateRoute";

function PrivateRoute({ component: Component, authed, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => authed === true
				? <Component {...props} />
				: <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
		/>
	)
}

function PublicRoute({ component: Component, authed, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => authed === false
				? <Component {...props} />
				: <Redirect to='/dashboard' />}
		/>
	)
}

export default class App extends Component {
	state = {
		authed: false,
		loading: true,
	}
	componentDidMount() {
		this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({
					authed: true,
					loading: false,
				})
			} else {
				this.setState({
					authed: false,
					loading: false
				})
			}
		})
	}
	componentWillUnmount() {
		this.removeListener()
	}
	render() {
		return this.state.loading === true ? <CenteredContentWrapper><h1>Loading</h1></CenteredContentWrapper> : (
			<Router>
				<main>
					<Route path="/" exact component={LandingPage} />
					<PrivateRoute authed={this.state.authed} path="/dashboard" exact component={DashboardPage} />
					<PrivateRoute authed={this.state.authed} path="/playlist" exact component={PlaylistPage} />
					<PrivateRoute authed={this.state.authed} path="/leaderboard" exact component={LeaderboardPage} />
					<PrivateRoute authed={this.state.authed} path="/notifications" exact component={PlaylistPage} />
				</main>
			</Router>
		);
	}
}
