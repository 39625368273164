import React, { Component } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router";
import logoSvg from "../assets/img/logo.svg";
import SoMuDiSvg from "../assets/img/SocialMusicDiscovery.svg";
import { firebaseAuth } from "../config/constants";
import CenteredContentWrapper from "../modules/CenteredContentWrapper";
import styles from "./LandingPage.module.scss";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { isSignedIn: false };
    this.signInFlow = "popup";
    this.uiConfig = null;
  }

  componentDidMount = () => {
    // For iOS full screen apps we use the redirect auth mode.
    firebaseAuth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user });
    });
  };

  render() {
    if ("standalone" in window.navigator && window.navigator.standalone) {
      this.signInFlow = "redirect";
    }
    this.uiConfig = {
      signInFlow: this.signInFlow,
      signInOptions: [firebaseAuth.GoogleAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccess: () => false,
      },
    };
    function loginButton(uiConfig) {
      return (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth()} />
      );
    }
    return this.state.isSignedIn ? (
      <Redirect to="/dashboard" />
    ) : (
      <div className={styles.LandingPage}>
        <CenteredContentWrapper fullscreen={true}>
          <img className={styles.logo} src={logoSvg} alt="Logo in waveform" />
          <div className={styles.slogan}>
            <img src={SoMuDiSvg} alt="" />
          </div>
          <br />
          <div className={styles.loginButton}>{loginButton(this.uiConfig)}</div>
        </CenteredContentWrapper>
      </div>
    );
  }
}

export default LandingPage;
