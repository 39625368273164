import { List } from "antd";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import closeSvg from "../assets/img/close.svg";
import styles from "./SongHistory.module.scss";

const SongHistory = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const baseDomainDB = "https://api.somuto.app";
  let email = firebase.auth().currentUser.email;

  useEffect(async () => {
    const response = await fetch(`${baseDomainDB}/userSongs.php?email=${email}`, {
      method: "GET",
    });
    const data = await response.json();
    setData(data);
    setLoading(false);
  }, []);

  return (
    <div className={styles.songList}>
      <div className={styles.closeButton}>
        <img
          src={closeSvg}
          alt="close button"
          onClick={() => {
            props.close();
          }}
        />
      </div>
      <h2>My History</h2>
      <div className={styles.songs}>
        <List
          itemLayout="horizontal"
          size="large"
          dataSource={data}
          loading={loading}
          renderItem={(item) => (
            <div className={styles.song}>
              <h3>{item.title}</h3>
              <p className={styles.artist}>{item.artist}</p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default SongHistory;
